@import "../../variables.modules";

.pink {
  color: $pink;
}

.green {
  color: $green;
}

.skills {
  // columns: 1;

  @media only screen and (min-width: 940px) {
    // columns: 2;
  }

  li {
    margin: 0rem .5rem .5rem!important;
    line-height: 1.75;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    border: 1px solid #888;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    display: inline-block;
  }
}